import * as React from "react";
import CookieBannerCH from "./CookieBannerCH";
import { ConsentValues, CountryCode } from "../../common/constants/Constants";
import CookieBannerNonCH from "./CookieBannerNonCH";
import { getCountryCode } from "../scripts/getCountryCode";

interface Props {
  isAccepted: ConsentValues | null;
}
interface State {
  countryCode: string | null;
}

class CookieBannerSwitch extends React.PureComponent<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      countryCode: null,
    };
  }


  componentDidMount() {
    const setCountryCode = (geolocationData: { country_code: string; }) => {
      this.setState({ countryCode: geolocationData.country_code });
    };
    if (this.props.isAccepted) {
      return null;
    }
    getCountryCode(setCountryCode)
  }

  render() {
    if (this.props.isAccepted !== null) {
      return null;
    }
    const { countryCode } = this.state;
    return (countryCode === CountryCode.CH)
      ? <CookieBannerCH isAccepted={this.props.isAccepted} />
      : (countryCode)
        ? <CookieBannerNonCH isAccepted={this.props.isAccepted} />
        : null; }
}

export default CookieBannerSwitch;

import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";
import { WHITE_SPACE } from "../../common/utils/constants";
import { cookieBannerOptionYes } from "../scripts/cookieBannerOptionYes";
import { ConsentValues, StorageKeys } from "../../common/constants/Constants";

interface Props {
  isAccepted: ConsentValues | null;
}

class CookieBannerCH extends React.PureComponent<Props> {

  componentDidMount() {
    localStorage.setItem(StorageKeys.consentModeCH, ConsentValues.Y);
    localStorage.setItem(StorageKeys.essentialCookies, ConsentValues.CH);
    localStorage.setItem(StorageKeys.analyticsCookies, ConsentValues.CH);
    localStorage.setItem(StorageKeys.marketingCookies, ConsentValues.CH);
  }

  render() {
    if (this.props.isAccepted !== null) {
      return null;
    }

        return (
      <div className="cookie__banner">
      <p className="cookie-banner__title">{dic("COOKIE CONSENT")}</p>
      <p className="cookie-banner__text">
        {dic("COOKIEBANNER TEXT SWITZERLAND")} {WHITE_SPACE}
        <Link
          className="cookie-banner__link"
          to={`/cms/${activeLang()}/${dic("CMSURL_DATENSCHUTZ")}`}
        >
          {dic("DATENSCHUTZERKLAERUNG")}
        </Link>
      </p>
        {/*class gtm-cookie-banner is used to identify the button for triggering a gtm event*/}
      <button className="btn" onClick={() => cookieBannerOptionYes()}>{dic("OK")}</button>
    </div>
    )
  }
}

export default CookieBannerCH;
